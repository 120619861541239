import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Agio = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Was ist eigentlich das Agio?" showCalc={false} />
            <Article>
                <p>
                    Du bist auf der Suche nach einer Immobilie und möchtest in eine Eigentumswohnung investieren. Dann
                    bist du nun möglicherweise das erste mal mit der Finanzwelt in Berührung gekommen. Vielleicht hast
                    du im Laufe deiner Recherchen schon den Begriff „Agio“ gelesen und erfahren, dass du dich damit
                    unbedingt auseinandersetzen solltest, wenn du dich für Investitionen in Immobilienfonds
                    interessierst. Wir erklären dir hier alles, was du darüber wissen solltest. Dann hast auch du den
                    Durchblick.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Investieren in Immobilien und das Agio</h2>
                <p>
                    Es gibt verschiedene Arten, um in Immobilien zu investieren. Du kannst entweder direkt in einzelne
                    Wohnungen oder Häuser investieren, oder du kannst Immobilienfonds an der Börse kaufen. Dort kannst
                    du auch Aktien von Immobiliengesellschaften kaufen. Jede dieser Möglichkeiten hat ihre eigenen Vor-
                    und Nachteile. Aber alle haben eines gemeinsam: dass für sie ein Agio zu zahlen ist. Aber was ist
                    das eigentlich genau?
                </p>
                <p>
                    Ein Agio ist ein Aufgeld, das zusätzlich zum{" "}
                    <Link to="/artikel/kosten-wohnung/" target="_blank" rel="noreferrer noopener">
                        Kaufpreis
                    </Link>{" "}
                    oder Kurswert gezahlt wird. Es ist z.B. für den Wertpapierhandel, Devisengeschäfte und beim Kauf von
                    Aktienfonds und Sachwertbeteiligungen zu entrichten. Das heißt, dass du das Agio auch bei
                    Investitionen in Immobilien beachten musst. Das Wort kommt – wie so viele Begriffe der Finanzwelt –
                    aus dem Italienischen Wort für Prämie und meinst einen Preisaufschlag oder ein Aufgeld auf den
                    eigentlichen Wert von Wertpapieren. Komplizierter ist es eigentlich auch nicht.
                </p>
                <hr />

                <h2>Was sagt das Agio über Immobilienfonds?</h2>
                <p>
                    Sobald der Preis von Immobilienfonds über den sogenannten Nettoinventarwert (NAV) steigt, spricht
                    man von einem Aufpreis bzw. einem Agio. Der NAV ist der Wert der Immobilie minus dem Fremdkapital.
                    Aber der bezahlte Preis an der Börse weicht häufig vom NAV ab. Sobald der Marktpreis also über dem
                    NAV liegt, spricht man von einem Agio. Wie hoch das Agio ist, hängt von verschiedenen Faktoren ab.
                    In den letzten Jahren war der Agio stark angestiegen. Zum Teil zahlten Menschen Preisaufschläge von
                    60% oder gar mehr. Beim Kauf an der Börse musste also eine Zeit lang ein ziemlich hohes Aufgeld
                    bezogen auf den Nettoinventarwert NAV bezahlt werden. Manche Finanzexpert*innen deuteten das als
                    Signal für eine drohende{" "}
                    <Link to="/artikel/immobilienblase/" target="_blank" rel="noreferrer noopener">
                        Immobilienblase
                    </Link>
                    , aber das ist nicht eingetreten. Während der Corona-Krise haben sich diese hohen Aufpreise aber
                    wieder deutlich gebessert. Ende März 2020 erreichten sie wieder ein Niveau von ca. 20 Prozent. Die
                    Situation hat sich also wieder beruhigt, allerdings muss man das natürlich differenziert betrachten.
                    Man darf das Marktzinsniveau dabei nicht unterschätzen, denn das hat einen großen Einfluss auf die
                    Bewertung von Liegenschaften. Informiere dich also umfassend und lasse dich bestenfalls beraten.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Hier geht’s zum Beratungsgespräch!
                    </a>
                </p>
                <hr />

                <h2>Wie interpretiere ich das Agio?</h2>
                <p>
                    Um das Agio richtig zu interpretiere musst du als Investor*in die Eigenheiten der verwendeten
                    Kennzahlen kennen, um davon ableiten zu können, ob ein Aufpreis in einem bestimmten Fall
                    gerechtfertigt ist, oder nicht. Eine Kennzahl gibt dir Informationen über den relativen Preis
                    gegenüber dem der Liegenschaft zugrunde liegenden Wert.
                </p>
                <hr />

                <h2>Woher weiß ich ob ein Agio zu zahlen ist?</h2>
                <p>
                    Wird ein Agio überhaupt erhoben oder nicht? Und wenn ja, wie hoch ist es? Das alles liegt in den
                    Händen des Emittenten bzw. des Anbieters. Wenn das Agio erhoben wird, darf das Anleger*innen
                    jedenfalls nicht verborgen bleiben, sondern muss vertraglich festgehalten werden. Die Angabe über
                    das Agio darf auch in Kaufangeboten oder Produktbeschreibungen niemals fehlen! Berechnet wird das
                    Agio von den Anbieter*innen selbst, eine einheitliche Formel gibt es hier nicht. Normalerweise wird
                    ein festgelegter prozentualer Anteil auf den Nennwert aufgeschlagen. Der verändert sich.
                </p>
                <hr />

                <h2>Warum ein Aufpreis überhaupt gerechtfertigt ist</h2>
                <p>
                    Wenn du direkt eine Immobilie kaufst, fallen ziemlich hohe Transaktionskosten an, während ein
                    Fondsanteil mit den zugrunde liegenden Immobilien einfach ohne Transaktionskosten an der Börse
                    gekauft werden kann. Für die Fondsanteile gibt es tägliche Preisstellungen und regelmäßige
                    Berichterstattung. Damit wird der Aufpreis, also das Agio, gerechtfertigt. Außerdem hat ein Fonds
                    mit mehreren Immobilien einen Diversifikationsvorteil gegenüber der Investition in eine einzelne
                    Immobilie.
                </p>
                <p>
                    Wenn du in Immobilien investieren willst, ist also ein Aufpreis zu zahlen. Für die Investition wird
                    ein Agio, also ein fixer Aufpreis, festgelegt und muss von dir mitgeplant werden. Der Preis ist
                    festgelegt und muss von dir mitgeplant werden. Es lohnt sich, sich hier Beratung über die aktuelle
                    Situation am Finanzmarkt einzuholen, wenn du neu auf dem Terrain bist. Auch durch eigene Recherchen
                    kann man es schaffen, die dem Finanzmarkt eigenen Vokabel zu lernen und sich von Begriffen wie Agio
                    nicht mehr einschüchtern zu lassen. Durch ein wenig Übung findet man sich schon bald besser zurecht.
                    Investitionen in Immobilien sind gut überlegte Handlungen, vor denen du immer gut informiert sein
                    solltest.
                </p>
            </Article>

            <BreadcrumbList page={"agio"}></BreadcrumbList>
            <ArticleStructuredData page={"agio"} heading={"Was ist eigentlich das Agio?"} />
        </Layout>
    );
};

export default Agio;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.agio", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
